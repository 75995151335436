import React from 'react'
import { assets } from './assets'

const AmmenitesDesc = (svg, tittle) => {
    return (
        <div className='d-flex flex-column col-12 col-sm-6 col-md-4 mb-4'>
            <div className='ammenitesBoxDesc '>
                {svg}
                <p className='am-tittle'>{tittle}</p>
            </div>
        </div>
    )
}

export const Ammenites = () => {
    return (
        <div className='ammenitesBox d-flex col-12 flex-wrap p-4 marginUtility' id='resources'>
            <div className='d-flex justify-content-around mb-4 my-4 container-fluid' id='ratesServices'>
                {assets.whiteShip}
                <h2 className="marina2 text-white">Amenities</h2>
                {assets.whiteShip}
            </div>
            <div className='col-12 d-flex flex-wrap justify-content-around'>
                {AmmenitesDesc(assets.gasMain, "Gas Diesel")}
                {AmmenitesDesc(assets.parking, "Gated Parking")}
                {AmmenitesDesc(assets.shipsStore, "Ships Store")}
                {AmmenitesDesc(assets.deli, "Coop Sandwiches")}
                {AmmenitesDesc(assets.juice, "Beverages")}
                {AmmenitesDesc(assets.ice, "Ice")}
                {AmmenitesDesc(assets.wifi, "Wi-Fi")}
            </div>
        </div>
    )
}

