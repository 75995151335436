import React from 'react';


export const ImageGrid = () => {
    return (
        <div className='container-fluid d-flex justify-content-center' >
            <div className="image-grid image-grid2 col-11 marginUtility" >
                <img
                    src='images/marinaImg1.jpg'
                    alt=''
                    

                />
                <img
                    src='images/gridimg.png'
                    alt='Sample'
                   
                />
                <img
                    src='images/gridImgNew.jpg'
                    alt=''
                   

                />
                <img
                    src='images/Rectangle5.png'
                    alt='Sample'
                    
                />
                <img
                    src='images/fish.png'
                    alt='Sample'
                    className='imgbg5'
                    
                />
                <img
                    src='images/imageGrid9.png'
                    alt='Sample'
                    className='imgbg5'
                    
                />
                <img
                    src='images/bevrages1.jpg'
                    alt='Sample'
                    className='imgbg5'
                    
                />
                <img
                    src='images/bevrages2.jpg'
                    alt='Sample'
                    className='imgbg5'
                    
                />
                <img
                    src='images/bevrages3.jpg'
                    alt='Sample'
                    className='imgbg5'
                />
            </div>
        </div>

    );
};

export default ImageGrid;
