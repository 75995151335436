import React from 'react';


export const ImageUi = () => {
  return (
    <div className="image-container " style={{background: "url('images/marinaImg9.jpg') lightgray 50% / cover no-repeat"}}>
      <div className="text-content ">
        <h1 className="main-title p-3 col-8">Toler's Cove Marina</h1>
        <p className="sub-title p-3 col-6">1610 Ben Sawyer Blvd, Mount Pleasant, SC, United States, 29464-4579</p>
      </div>
      <svg className="svgIcon svgIcon1" xmlns="http://www.w3.org/2000/svg" width="623" height="648" viewBox="0 0 823 648" fill="none">
        <path d="M823 324C823 324 616.123 5.19923e-05 202.339 3.39052e-05L-663 -3.91997e-06L-663 648L202.339 648C616.123 648 823 324 823 324Z" fill="#1656A4"/>
      </svg>
      <svg className="svgIcon svgIcon2" xmlns="http://www.w3.org/2000/svg" width="653" height="648" viewBox="0 0 853 648" fill="none">
        <path d="M853 324C853 324 646.123 5.19923e-05 232.339 3.39052e-05L-633 -3.91997e-06L-633 648L232.339 648C646.123 648 853 324 853 324Z" fill="#4578B6"/>
      </svg>
     
    </div>
  );
}



