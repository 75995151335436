import React, { useState, useEffect } from 'react';
import "flatpickr/dist/themes/material_green.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { assets } from './assets';
import { useLocation } from 'react-router-dom';
import axios from "axios"
import DatePicker from 'react-datepicker';
import moment from 'moment';

export const AboutUs = () => {
    const location = useLocation();
    const [length, setLength] = useState('');
    const [arrival, setArrival] = useState('');
    const [departure, setDeparture] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [sendersMail, setEmail] = useState('');
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [error, setError] = useState('')
    const state = location.state;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const mailMap = {
        "John Townsend Cooper": "JTC@graybayoutdoors.com",
        "Captain Shane Sinclair": "captshanesinclair@gmail.com"
    }

    const removeMsg = () => {
        setTimeout(() => { setError("") }, 5000)
    }

    const handleBooking = async () => {
        const apiUrl = `${process.env.REACT_APP_MAIL}/capMail`;
        let email = mailMap[state.name]
        const requestData = {
            length,
            arrival: moment(arrival).format('MMMM Do YYYY, HH:mm:'),
            departure: moment(departure).format('MMMM Do YYYY, HH:mm'),
            couponCode,
            field: 'charter',
            email,
            sendersMail,
            firstName,
            lastName,
            chartersName: state.name
        };
        const validateFields = () => {
            let flag = false;
            if (!firstName) {
                setError('First name is required');
                return true;
            }
            if (!lastName) {
                setError('Last name is required');
                return true;
            }
            if (!arrival) {
                setError('Arrival date is required')
                return true;
            }
            if (!departure) {
                setError('Departure date is required');
                return true;
            }
            if (!sendersMail.includes('@')) {
                setError('Email must be valid');
                return true;
            }
            return flag;
        };
        let flag = validateFields();
        if (flag) {
            removeMsg();
            return;
        }
        const response = await axios.post(apiUrl, requestData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            setError("noError")
            setArrival('');
            setCouponCode("");
            setDeparture('');
            setEmail('');
            setFirstName('');
            setLastName('');
            setLength('')
            removeMsg()
            return;
        }
        else {
            setError("Some error has occured")
            removeMsg()
            return;
        }

    };

    return (
        <div className="container my-5 flex-wrap" style={{ display: "flex", justifyContent: "center", margin: "0", width: "100%" }}>
            <div className="row d-flex  justify-content-around">
                 <h2 className='col-12 text-primary d-flex justify-content-center' >{location && location.state && location.state.name}</h2>
                <div className="col-12 col-md-6  p-4 mt-2 d-flex flex-column align-items-center ">
                    <h1 className="text-primary col-12 mb-0" style={{ fontFamily: 'Manrope', fontSize: '24px' }}>About</h1>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <p  >{state && state.about}</p>
                    <p> {state && state.address}</p>
                    <h2 className="text-primary col-12" style={{ fontFamily: 'Manrope', fontSize: '24px' }}>Photos</h2>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <div className="row">
                        {state && state.images.length && state.images.map((item) => (
                            <div key={item} className="col-12 col-md-6 col-sm-12 mb-4">
                                <div className="" style={{ width: '100%', backgroundColor: '#f0f0f0', borderRadius: '5px' }}>
                                    <img src={item} style={{ height: "13rem", borderRadius: "8px" }} alt="About Us" />
                                </div>
                            </div>
                        ))}
                    </div>

                    <h2 className="text-primary col-12" style={{ fontFamily: 'Manrope', fontSize: '24px' }}>Services</h2>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <div className="row d-flex justify-content-around align-items-center col-12">
                        <div className="col-6">
                            <div className="form-check d-flex align-items-center">
                                <label className="form-check-label h5" htmlFor="pumpPrice" style={{ marginLeft: "5px", }}>Inshore Charters</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check d-flex align-items-center">
                                <label className="form-check-label h5" htmlFor="amenities" style={{ marginLeft: "5px" }}>Offshore Charters</label>
                            </div>
                        </div>
                    </div>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <h2 className="text-primary col-12" style={{ fontFamily: 'Manrope', fontSize: '24px', marginTop: '28px' }}>Info</h2>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <div className="row d-flex justify-content-center align-items-center mb-4 col-12">
                        <div className="col-12">
                            <div className="form-check justify-content-start">
                                <h5 className='col-12'>Cancellation Policy -48 Hours Policy</h5>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Second Half */}
                <div className="col-12 col-md-5  py-4 mt-2 d-flex flex-column align-items-center" id="specific">
                    <h2 className="text-primary col-12" style={{ fontFamily: 'Manrope', fontSize: '24px' }}>Booking Request</h2>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <div className="row mb-3 col-12">
                        <div className="col-12 col-md-5 mb-1 ">
                            <label htmlFor="First Name" className="form-label">First Name<span style={{ color: "red" }}>*</span></label>
                            <input type="text" className="form-control" id="First Name" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                        </div>
                        <div className="col-12 col-md-5">
                            <label htmlFor="Last Name" className="form-label">Last Name<span style={{ color: "red" }}>*</span></label>
                            <input type="text" className="form-control" id="Last Name" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                        </div>
                    </div>

                    <div className="row mb-3 col-12">
                        <div className="col-12 col-md-5">
                            <label htmlFor="arrival" className="form-label col-12">Arrival<span style={{ color: "red" }}>*</span></label>
                            <DatePicker
                                selected={arrival}
                                onChange={(date) => setArrival(date)}
                                showTimeSelect
                                dateFormat="MM-dd-yyyy h:mm aa" // Format for date and time
                                timeFormat="HH:mm" // Optional, if you want to specify time format
                                timeIntervals={60} // Time intervals in minutes
                                placeholderText="MM-DD-YYYY  " // Placeholder text
                                className="form-control"
                            />
                        </div>
                        <div className="col-12 col-md-5">
                            <label htmlFor="departure" className="form-label col-12">Departure<span style={{ color: "red" }}>*</span></label>
                            <DatePicker
                                selected={departure}
                                onChange={(date) => setDeparture(date)}
                                showTimeSelect
                                dateFormat="MM-dd-yyyy h:mm aa" // Format for date and time
                                timeFormat="HH:mm" // Optional, if you want to specify time format
                                timeIntervals={60} // Time intervals in minutes
                                placeholderText="MM-DD-YYYY " // Placeholder text
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row mb-3 col-12">
                        <div className="col-12 col-md-10">
                            <label htmlFor="email" className="form-label">Email<span style={{ color: "red" }}>*</span></label>
                            <input type="email" className="form-control" id="email" onChange={(e) => setEmail(e.target.value)} value={sendersMail} />
                        </div>
                    </div>
                    <div className='row col-12 d-flex'>
                        <div className='col-6'><span style={{ color: "red" }}>*</span>
                            Fields are mandatory</div>
                        <div className='col-6'>{!error.length ? "" : error === "noError" ? <span className='text-success h7'>Request Has Been Sent For Booking</span> : <span className='text-danger h7'>{error}</span>}</div>
                    </div>
                    <div className='row col-12 d-flex'>
                        <button className="btn-new mt-4 col-12 col-md-10" onClick={handleBooking}>
                            Submit  Booking Request
                            {assets.whiteShip}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

