import React, { useRef } from 'react';
import { assets } from './assets';
import { useNavigate } from 'react-router-dom';

export const BookingCards = () => {
  const navRef = useRef()
  const navigate = useNavigate();
  const transMap = [{ amp: "50AMP", price: "$25.00/week" }]
  const sendRequest = async () => {
    navigate("/transient-booking")
  }
  return (
    <div className="Row d-flex justify-content-around container-fluid my-4 mb-4">
      <a className="nav-link navFontColor" href="#footer" ref={navRef} style={{ display: "none" }}>Contact Us</a>
      <div className="d-flex flex-column col-12 col-md-6 col-lg-3 bookingCards p-4 align-items-center mb-4">
        <div className="svg-container mb-3">{assets.shipSvg}</div>
        <h2 className="marina">Transient</h2>
        <div className="col-12 bg1 d-flex flex-column align-items-center p-1">
          <p className="text-white h5 mb-1">Starting at:$3</p>
          <p className="text-white h5 mb-1">Per Ft for Transient</p>
          <p className="text-white h5 mb-1">$28 Monthly</p>
        </div>
        <div className="Row col-12 d-flex justify-content-around">
          {
            transMap.map((item) => {
              return <div className="col-12 d-flex   label-box mb-3 mt-4" style={{ padding: "10px", textAlign: "start" }} key={item.amp}>
                <div className="col-12" style={{ width: "100%" }}>
                  <label htmlFor="option1" className="label col-12" style={{ fontSize: "1rem", margin: "0" }}>{item.amp}</label>
                  <p style={{ fontSize: "1rem", margin: "0" }} className=''>{item.price}</p>
                </div>
              </div>
            })
          }
        </div>
        <button className="btn-new mt-4 col-11" onClick={sendRequest}>
          Contact Us
          {assets.avg}
        </button>
      </div>
    </div>
  );
};

