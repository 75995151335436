import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const AdminPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('')
  const navigate = useNavigate();
  const removeMsg = () => {
    setTimeout(() => { setError("") }, 5000)
  }
  const handleSubmit = async () => {
    if (!username.includes('@')) {
      setError('Email must be valid');
      return;
  }
    const apiUrl = `${process.env.REACT_APP_MAIL}/admin`
    const response = await axios.post(apiUrl, { name: username, password: password }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data && response.data.key && response.data.key.length) {
      localStorage.setItem("key",response.data.key)
      navigate("/edit")
    }
    if(response.data && response.data.msg)setError(response.data.msg)
    removeMsg()
  };

  return (
    <div className='container-fluid d-flex justify-content-center align-items-center row' style={{ height: '89vh', backgroundColor: "whitesmoke" }}>
      <div className='d-flex flex-column justify-content-center  col-12 col-lg-3 p-5' style={{ backgroundColor: "white" }}>
        <div className='d-flex justify-content-center'><img src='images/Logo.png' alt='not found' style={{ width: "5rem", height: "5rem", marginBottom: "3rem" }}></img></div>
        <label htmlFor="username" style={{ display: "inline-flex", justifyContent: "start" }}>Username</label>
        <input
          type="email"
          id="username"
          className='mb-3'
          value={username}
          style={{ color: "black" }}
          onChange={(e) => setUsername(e.target.value)}
        />

        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          className='mb-3'
          value={password}
          style={{ color: "black" }}
          onChange={(e) => setPassword(e.target.value)}
        />
        <p style={{ color: "red" }}>{error.length ? error : ""}</p>

        <button
          type="button"
          className="btn btn-success"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

