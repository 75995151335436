import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Ammenites } from './Ammenites';

const Navbar = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelectorAll('.other').forEach(element => {
      element.addEventListener('click', () => {
        navigate('/');
      });
    });
  }, [navigate]);

  return (
    <nav className="navbar navbar-expand-lg navbarStyles  sticky-top bg-white container-fluid" style={{ overflow: "hidden" }}>
      <div className="container-fluid d-flex justify-content-between">
        <div className='d-flex align-items-center'>
          <a className="logoImg p-0 m-0 nav-t nav-item other col-sm-6" style={{ fontWeight: "900px" }} href="#">
            {<img src="images/Logo.png" style={{ height: "4rem", width: "10rem", display: "block", margin: "0", padding: "0" }}></img>}
          </a>
          <button className="navbar-toggler nav-t col-sm-6" style={{ height: "3rem", width: "4rem", border: "2px solid black" }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className='d-flex flex-column  align-items-center'>
          <div className='d-flex  align-items-center'>
            <a className="navbar-brand brandName p-0 m-0 nav-t nav-item other" style={{ fontWeight: "900px" }} href="#">
              Toler's Cove Marina LLC
            </a>
          </div>
          
        </div>
        <div className="collapse navbar-collapse col-12 col-lg-7" id="navbarNav">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <li className="nav-item other">
              <a className="nav-link navFontColor" aria-current="page" href="#">Our Marina</a>
            </li>
            <li className="nav-item other">
              <a className="nav-link navFontColor" href="#ratesServices">Rates & Services</a>
            </li>
            <li className="nav-item other">
              <a className="nav-link navFontColor" href="#resources">Amenites  </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

