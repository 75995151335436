import React,{useState,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { assets } from './assets';
import axios from 'axios';

export const Carousal = () => {
  const [prices, setPrices] = useState();
const getPrices = async () => {
  try {
    const apiUrl = `${process.env.REACT_APP_MAIL}/get-prices`;
    const response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      setPrices(response.data);
    } else {
      setPrices({ superGas: 12, diesel: 23 });
    }
  } catch (error) {
    console.error("Error fetching prices:", error);
    setPrices({ superGas: 12, diesel: 23 });
  } 
}
useEffect(() => {
  getPrices();
}, [])
  return (
    <div className='secondHeading d-flex flex-column justify-content-center align-items-center mb-4 container'>
      <div className='d-flex justify-content-center mb-4 my-4 container-fluid' id='ratesServices'>
        {assets.shipSvg}
        <h2 className="marina2 text-center mx-3">Our Marina</h2>
        {assets.shipSvg}
      </div>
      <div className="mb-4 d-flex justify-content-center text-center col-12 prices flex-wrap">
        <div className='col-12 col-md-4 h3' style={{color: "#1656A4"}}>Listed Pump Price</div>
        <div className='col-12 col-md-2 h4' style={{color:"#1d4b83"}}>Super Gas:${prices && prices.superGas}</div>
        <div className='col-12 col-md-2 h4' style={{color:"#1d4b83"}}>Disel:${prices && prices.diesel}</div>
      </div>
      <div className="container-fluid my-4 d-flex justify-content-center">
        <div className="row utilitesFlex d-flex justify-content-center">
          {[
            { imgSrc: 'images/forkKnife.png', title: 'Better Amenities', description: [
              'Convenient Ship’s Store for all your boating and fishing needs',
              'Access to world-class shopping and dining nearby',
              'Overnight lodgings nearby',
              'Much more!'
            ]},
            { imgSrc: 'images/image4.png', title: 'Better Service', description: [
              'Convenient Ship’s Store for all your boating and fishing needs',
              'Deli Kitchen',
              'Access to world-class shopping and dining nearby',
              'Overnight lodgings nearby',
              'Much more!'
            ]},
            { imgSrc: 'images/image6.png', title: 'Smart Technology', description: [
              'Convenient Ship’s Store for all your boating and fishing needs',
              'Deli Kitchen',
              'Access to world-class shopping and dining nearby',
              'Overnight lodgings nearby',
              'Much more!'
            ]},
            { imgSrc: 'images/image3.png', title: 'Premier Location', description: [
              'Convenient Ship’s Store for all your boating and fishing needs',
              'Deli Kitchen',
              'Access to world-class shopping and dining nearby',
              'Overnight lodgings nearby',
              'Much more!'
            ]}
          ].map((item, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-3 mb-3 d-flex justify-content-center align-items-center position-relative">
              {assets.smallShipSvg && (
                <div className='svg-overlay text-center col-12' style={{zIndex:"12"}}>
                  {assets.smallShipSvg}
                  <div className='text d-flex flex-column justify-content-center col-12'>
                    <h3>{item.title}</h3>
                    <ul style={{ listStyle: "none", margin: 0, padding: 0 ,visibility:`${index>0&&"hidden"}`}}>
                      {item.description.map((desc, idx) => <li key={idx}>{desc}</li>)}
                    </ul>
                  </div>
                </div>
              )}
              <img src={item.imgSrc} className='img-fluid' alt={item.title} style={{ zIndex: "-1",height:"28rem" ,width:"20rem"}}  />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

