import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PriceManager = () => {
    const [prices, setPrices] = useState({
        superGas:'',
        diesel:''
    });
    const [error, setError] = useState('')
    const [editable, setEditable] = useState(false);
    const [duplicateData,setDuplicateData] = useState({
        superGas:'',
        diesel:''
    });
    const navigate = useNavigate()
    useEffect(()=>{
        if(!localStorage.getItem("key")){
            navigate("/")
        }
    },[])
    const getPrices = async () => {
        let apiUrl = `${process.env.REACT_APP_MAIL}/get-prices`
        const response = await axios.get(apiUrl, {}, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) { setPrices(response.data);setDuplicateData(response.data) }


    }
    useEffect(() => {
        getPrices();
    }, [])
    const removeMsg = () => {
        setTimeout(() => { setError("") }, 3000)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPrices((prevPrices) => ({
            ...prevPrices,
            [name]: Number(value),
        }));
    };

    const toggleEditable = async () => {
        if (editable) {
            if(prices.superGas=== duplicateData.superGas && prices.diesel==duplicateData.diesel){
                setError("Prices are same")
                removeMsg()
                return ;
            }
            let apiUrl = `${process.env.REACT_APP_MAIL}/update-prices`
            const response = await axios.post(apiUrl, prices, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                if(response && response.data && response.data.message){setError(response.data.message)
                    getPrices()
                }
                removeMsg()
            }

        }
        setEditable(!editable);
    };

    return (
        <div className="container-fluid py-5" style={{ backgroundColor: 'whitesmoke' }}>
            <div className="row justify-content-center">
                <div className="col-xs-12 col-md-6">
                    <div className="card shadow">
                        <div className="card-body">
                            <h5 className="card-title text-center">Current Prices</h5>
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="superGas" className="form-label">Gas</label>
                                    <input
                                        type="number"
                                        id="superGas"
                                        name="superGas"
                                        value={prices && prices.superGas?prices.superGas:""}
                                        onChange={handleChange}
                                        readOnly={!editable}
                                        className="form-control"
                                        style={{ backgroundColor: editable ? 'white' : '#f8f9fa' }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="diesel" className="form-label">Diesel</label>
                                    <input
                                        type="number"
                                        id="diesel"
                                        name="diesel"
                                        value={prices && prices.diesel?prices.diesel:""}
                                        onChange={handleChange}
                                        readOnly={!editable}
                                        className="form-control"
                                        style={{ backgroundColor: editable ? 'white' : '#f8f9fa' }}
                                    />
                                </div>
                                <div className="text-center d-flex flex-wrap align-items-center col-12">
                                    <p style={{ color: "blue" }} className='col-12'>{error.length ? error : ""}</p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={toggleEditable}
                                        style={{fontSize:"1rem",fontWeight:"bold",marginBottom:"1rem"}}
                                    >
                                        {editable ? 'Save' : 'Edit Prices'}
                                    </button>
                                    {
                                        editable ?<button
                                        type="button"
                                        className="btn btn-primary pricemanageButton"
                                        style={{backgroundColor:"red",fontSize:"1rem",border:"1px solid red",fontWeight:"bold",marginBottom:"1rem"}}
                                        onClick={() => setEditable(false)}
                                    >
                                        {"Cancel"}
                                    </button>:""
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PriceManager;

