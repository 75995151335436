import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { assets } from './assets';
import axios from "axios"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const Tansient = () => {
    const [length, setLength] = useState('');
    const [arrival, setArrival] = useState('');
    const [departure, setDeparture] = useState('');
    const [sendersMail, setEmail] = useState('');
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [error, setError] = useState('')
    useEffect(() => {
        // Scroll to top when the component mounts
        window.scrollTo(0, 0);
      }, []);

    const removeMsg = () => {
        setTimeout(() => { setError("") }, 5000)
    }

    const amenitesMap = [
        {
            svg: assets.iceBlack,
            name: "Ice"
        },
        {
            svg: assets.gasBlack,
            name: "Gas"
        },
        {
            svg: assets.blackParking,
            name: "Free Parking"
        },
        {
            svg: assets.blackDeli,
            name: "Coop Sandwich"
        },
        {
            svg: assets.blackJuice,
            name: "Beverages"
        },
        

    ]

    const handleBooking = async () => {
        const apiUrl = `${process.env.REACT_APP_MAIL}/sendMail`;
        const requestData = {
            length,
            arrival,
            departure,
            sendersMail,
            firstName,
            lastName,
        };
        const validateFields = () => {
            function isPositiveNumber(input) {
                const number = Number(input);
                return !isNaN(number) && number > 0;
            }

            let flag = false;
            if (!firstName) {
                setError('First name is required');
                return true;
            }
            if (!lastName) {
                setError('Last name is required');
                return true;
            }
            if (!arrival) {
                setError('Arrival date is required')
                return true;
            }
            if (!departure) {
                setError('Departure date is required');
                return true;
            }
            if (!sendersMail.includes('@')) {
                setError('Email must be valid');
                return true;
            }
            if (!isPositiveNumber(length)) {
                setError('Please Enter a valid Length');
                return true;
            }

            return flag;
        };
        let flag = validateFields();
        if (flag) {
            removeMsg();
            return;
        }
        const response = await axios.post(apiUrl, requestData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            setError("noError")
            setArrival('');
            setDeparture('');
            setEmail('');
            setFirstName('');
            setLastName('');
            setLength('')
            setLength('')
            removeMsg()
            return;
        }
        else {
            setError("Some error has occured")
            removeMsg()
            return;
        }
    };

    return (
        <div className="container my-5 col-12 p-4" style={{ display: "flex", justifyContent: "center", margin: "0", width: "100%" }}>
            <div className="row d-flex justify-content-around ">
                <div className="col-12 col-md-6 d-flex justify-content-around">
                    <div className=" col-12 mt-3 py-4">
                        <h1 className="text-primary" style={{ fontFamily: 'Manrope', fontSize: '24px' }}>About</h1>
                        <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                        <p>Toler's Cove Marina is a secure, gated marina with convenient parking and the closest route to the ocean, but it also offers seamless service for transient dockage. Boaters can easily access all the amenities that surround this prime location, making it an ideal stop for both short and extended stays. The Marina store is fully stocked with all the essentials, and fuel is always offered at competitive prices. Additionally, for those looking for short-term accommodations in the area, Toler's Cove Marina can be contacted for personalized recommendations, ensuring a smooth and enjoyable experience on and off the water.</p>
                        <h2 className="text-primary" style={{ fontFamily: 'Manrope', fontSize: '24px' }}>Photos</h2>
                        <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                        <div className="row">
                            {[1, 2, 3, 4, 5, 6, 7, 8,9].map((item) => (
                                <div key={item} className="col-12 col-md-6 col-sm-12 mb-4">
                                    <div className="" style={{ width: '100%', backgroundColor: '#f0f0f0', borderRadius: '5px' }}>
                                        <img src={`images/marinaImg${item}.jpg`} style={{ height: "18rem", borderRadius: "8px" }} alt="About Us" />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <h2 className="text-primary" style={{ fontFamily: 'Manrope', fontSize: '24px', marginTop: '28px' }}>Amenities</h2>
                        <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                        <div className="row d-flex  mb-4 col-12 flex-wrap" >
                            <div className="col-12 d-flex flex-wrap ">
                                {
                                    amenitesMap.map((item) => {
                                        return <div className="form-check d-flex align-items-center col-6 mb-2 assets" key={item.name}>
                                            {item.svg}
                                            <label className="form-check-label h6" htmlFor="pumpPrice" style={{ marginLeft: "5px", color: "#0d6efd" }}>{item.name}</label>
                                        </div>
                                    })
                                }
                            </div>
                        </div>

                        <h2 className="text-primary" style={{ fontFamily: 'Manrope', fontSize: '24px', marginTop: '28px' }}>Info</h2>
                        <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                        <div className="row d-flex justify-content-center align-items-center mb-4">
                            <div className="col-12">
                                <div className="form-check justify-content-start">
                                    <h5>Cancellation Policy -48 Hours Policy</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-5  py-4  " id="specific">
                    <div className="row mb-3">
                        <h1 className="text-primary mt-4" style={{ fontFamily: 'Manrope', fontSize: '24px' }}>Reservation</h1>
                        <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                        <div className="col-12 col-md-5">
                            <label htmlFor="First Name" className="form-label">First Name<span style={{ color: "red" }}>*</span></label>
                            <input type="text" className="form-control mb-1" id="First Name" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                        </div>
                        <div className="col-12 col-md-5">
                            <label htmlFor="Last Name" className="form-label">Last Name<span style={{ color: "red" }}>*</span></label>
                            <input type="text" className="form-control" id="Last Name" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 col-md-5">
                            <label htmlFor="arrival" className="form-label col-12">Arrival<span style={{ color: "red" }}>*</span></label>
                            <DatePicker
                                selected={arrival}
                                onChange={(date) => setArrival(date)}
                                dateFormat="MM-dd-yyyy"
                                className="form-control col-md-5 col-12 mb-1"
                                placeholderText="mm-dd-yyyy"
                                popperPlacement="bottom" 
                            />
                        </div>
                        <div className="col-12 col-md-5">
                            <label htmlFor="departure" className="form-label col-12">Departure<span style={{ color: "red" }}>*</span></label>
                            <DatePicker
                                selected={departure}
                                onChange={(date) => setDeparture(date)}
                                dateFormat="MM-dd-yyyy"
                                className="form-control col-md-6 col-12"
                                placeholderText="mm-dd-yyyy"
                                popperPlacement="bottom" 
                            />
                        </div>
                    </div>
                    <div className="row mb-3 ">
                        <div className="col-12 col-md-5">
                            <label htmlFor="email" className="form-label">Email<span style={{ color: "red" }}>*</span></label>
                            <input type="email" className="form-control mb-1" id="email" onChange={(e) => setEmail(e.target.value)} value={sendersMail} />
                        </div>
                        <div className="col-12 col-md-5">
                            <label htmlFor="First Name" className="form-label">Length<span style={{ color: "red" }}>*</span></label>
                            <input type="number" className="form-control" id="First Name" value={length} onChange={(e) => { setLength(e.target.value) }} />
                        </div>
                    </div>
                    {!error.length ? "" : error === "noError" ? <span className='text-success h7'>Request Has Been Sent For Reservation</span> : <span className='text-danger h7'>{error}</span>}
                    <div className='col-6'>
                        <span style={{ color: "red" }}>*</span>
                        Fields are mandatory
                    </div>
                    <button className="btn-new mt-4 col-12 " onClick={handleBooking}>
                        Submit  Reservation Request
                        {assets.whiteShip}
                    </button>
                </div>
            </div>
        </div>
    );
};