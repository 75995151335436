import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const ImageCard = ({ capDetails }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [hover, setHover] = useState(false);
    const [scale, setScale] = useState(1); // State to manage image scaling for zoom effect
    const navigate = useNavigate();

    useEffect(() => {
        let intervalId;

        if (hover) {
            intervalId = setInterval(() => {
                setScale(0.8);
                setTimeout(() => {
                    setCurrentImageIndex((prevIndex) =>
                        prevIndex === capDetails.images.length - 1 ? 0 : prevIndex + 1
                    );
                    setScale(1); // Restore to full size
                }, 700); // Adjust this duration to match the transition duration
            }, 1700); // Total duration before switching images (1.5s image view + 0.5s zoom effect)

        } else {
            setCurrentImageIndex(0); // Reset to the first image when not hovered
        }

        return () => clearInterval(intervalId); // Cleanup the interval on unmount or when hover state changes
    }, [hover, capDetails.images.length]);

    return (
        <div
            className="col-12 col-md-6 d-flex flex-column justify-content-center mb-4 align-items-center"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {capDetails.images[currentImageIndex] ? <img
                src={capDetails.images[currentImageIndex]}
                className="imgh img-fluid"
                alt="Fisher 1"
                style={{
                    height: "23rem",
                    width: "32rem",
                    borderRadius: "18px",
                    transition: "transform 0.5s ease-in-out", // Add transition for transform
                    transform: `scale(${scale})`, // Apply scale state for zoom effect
                }}
            /> : <div class="spinner-border" role="status">
            </div>}
            <h2 className="marina3">{capDetails.name}</h2>
            <button
                style={{ backgroundColor: "#32a0ec" }}
                className="btn-new col-9 d-flex align-items-center justify-content-evenly"
                onClick={() => navigate('/about', { state: capDetails })}>
                Book Now
            </button>
        </div>

    );
};
