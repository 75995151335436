
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Navbar from './marinaUi/navbar';
import { Tansient } from './marinaUi/Transient';
import { AboutUs } from './marinaUi/Aboutus';
import { Home } from './marinaUi/Home';
import { AdminPage } from './marinaUi/AdminPage';
import PriceManager from './marinaUi/priceManager';

function App() {
  return (
    <div className="container-fluid p-0">
      <Navbar />
      <div className=''>
        <div className='col-12 d-flex justify-content-center flex-column align-items-center'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path = "/edit" element={<PriceManager/>}/>
            <Route path="/transient-booking" element={<Tansient/>} />
            
          </Routes>
        </div>
      </div>





    </div>
  );
}

export default App;
